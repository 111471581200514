import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import { FiSearch } from "react-icons/fi";
import { MdDownload } from "react-icons/md";
import rupeeIcon from "../../assets/icons/rupee.png";

function Holdings() {
  const {
    setWatchListShown,
    setPageName,
    demoHolding,
    setDemoHolding,
    totalCur,
    setTotalCur,
    totalInvestment,
    setTotalInvestment,
    totalPnl,
    setTotalPnl,
    setBopen,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchangeToken,
    setChartToken,
    setBuyAndSellExchange,
    setBuyAndSellModalLivePrice,
    setQty,
    setLotSize,
    setBuyAndSellAction,
    setIsToggled,
  } = useContext(MyContext);
  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    // setWatchListShown(true);
    //console.log("Demo holding : ",demoHolding);

    setPageName("Holdings");
  });

  const onOpenModal = (
    action,
    trading_symbol,
    Exchange,
    token,
    lastPrice,
    lotSize
  ) => {
    // console.log("optionchain : ", item);

    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(token);
    setChartToken(token);
    setBuyAndSellExchange(Exchange);
    setBuyAndSellModalLivePrice(lastPrice);
    setQty("");
    setLotSize(lotSize);
    if (action === "BUY") {
      setBuyAndSellAction("buy");
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      setIsToggled(true);
    }
  };
  return (
    <Layout>
      <div
        className="w-full h-auto border-[1px] mb-[10px]"
        style={{ borderColor: "#E6E6E6", borderRadius: 4 }}
      >
        <div className="flex  items-center justify-between ml-[16px] mt-[24px] mr-[16px] mb-[23px]">
          <div className="flex  items-center ">
            <h1 className="text-xl font-bold mr-[36px]">Holdings</h1>
            <div className="flex space-x-1">
              <button
                className="px-3 py-1 border  bg-green-100 flex flex-row items-center"
                style={{
                  fontSize: 14,
                  backgroundColor: "#D4EDDA",
                  color: "#155724",
                  //color: isAllSelected ? "#155724" : "#817E7E",
                  //backgroundColor: isAllSelected ? "#D4EDDA" : "#FFFFFF",
                }}
              >
                Stocks | {demoHolding.length}
              </button>
            </div>
          </div>

          <div>
            <button>
              <FiSearch />
            </button>
            <button style={{ width: 14, height: 16, marginLeft: 16 }}>
              <MdDownload />
            </button>
          </div>
        </div>

        <table className="min-w-full  bg-white orderTable">
          <thead className="orderTable">
            <tr>
              <th>Instrument</th>
              <th>Qty.</th>
              <th>Avg. cost</th>
              <th>LTP</th>
              <th>Cur. val</th>
              <th>P&L</th>
              {/* <th>Day Profit</th> */}
              <th>Net chg.</th>
            </tr>
          </thead>
          <tbody>
            {demoHolding.map((holding, hindex) => {
              //console.log("holding : ",holding);

              let cur_value =
                holding.buy_qty * parseFloat(holding.instrument__last_price);
              let pnl = cur_value - holding.buy_avg * holding.buy_qty;
              return (
                <tr key={hindex}>
                  <td className="px-4 py-2 border orderTabletd">
                    <div
                      className=""
                      onMouseOver={() => {
                        const newDemoHolding = demoHolding.map((it, idx) => {
                          if (hindex === idx) {
                            it.isHover = true;
                          } else {
                            it.isHover = false;
                          }

                          return it;
                        });
                        setDemoHolding(newDemoHolding);
                      }}
                      onMouseOut={() => {
                        const newDemoHolding = demoHolding.map((it, idx) => {
                          it.isHover = false;

                          return it;
                        });
                        setDemoHolding(newDemoHolding);
                      }}
                    >
                      <div className="flex items-center">
                        <h2 style={{ fontSize: 14, color: "#363636" }}>
                          {holding.instrument__trading_symbol}
                        </h2>
                        <span
                          className="ml-[4px]"
                          style={{ fontSize: 10, color: "#817E7E" }}
                        >
                          {holding.instrument__exchange}
                        </span>
                      </div>
                      {holding.isHover && (
                        <div className="addorexit">
                          <button
                            className="addBtn cursor-pointer"
                            onClick={() => {
                              onOpenModal(
                                "BUY",
                                holding.instrument__trading_symbol,
                                holding.instrument__exchange,
                                holding.instrument__exchange_token,
                                holding.instrument__last_price,
                                holding.instrument__lot_size
                              );
                            }}
                          >
                            <p>Add</p>
                          </button>
                          <button
                            className="exitBtn cursor-pointer"
                            onClick={() => {
                              onOpenModal(
                                "SELL",
                                holding.instrument__trading_symbol,
                                holding.instrument__exchange,
                                holding.instrument__exchange_token,
                                holding.instrument__last_price,
                                holding.instrument__lot_size
                              );
                            }}
                          >
                            <p>Exit</p>
                          </button>
                        </div>
                      )}
                    </div>
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {holding.buy_qty}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {holding.buy_avg}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {parseFloat(holding.instrument__last_price).toFixed(2)}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {cur_value.toFixed(2)}
                  </td>
                  <td className="px-4 py-2 border orderTabletd">
                    {pnl.toFixed(2)}
                  </td>

                  <td className="px-4 py-2 border orderTabletd">
                    {(parseFloat(holding.instrument__last_price) /
                      holding.change ===
                    0
                      ? 1
                      : holding.change
                    ).toFixed(2) * 100}
                    %
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="mt-[27px] mb-[15px]  flex justify-end space-x-9 px-6 ">
          <div>
            <div className="text-xs text-gray-500">Total Investment</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">
                {totalInvestment.toFixed(2)}
              </span>
            </div>
          </div>

          <div>
            <div className="text-xs text-gray-500">Current Value</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">{totalCur.toFixed(2)}</span>
            </div>
          </div>

          {/* <div>
            <div className="text-xs text-gray-500">Day P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">0.00(%)</span>
            </div>
          </div> */}

          <div>
            <div className="text-xs text-gray-500">Today's P&L</div>
            <div className="flex flex-row items-center">
              <img
                src={rupeeIcon}
                alt="Rupee icon"
                className="w-3.5 h-3.5 mr-1.5"
              />
              <span className="text-lg font-bold">{totalPnl.toFixed(2)}</span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Holdings;
