import React, { useEffect, useContext, useState } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import rupeeIcon from "../../assets/icons/rupee.png";
import CardIcon from "../../assets/icons/Card.png";
import { toast } from "react-toastify";
import { Button } from "@headlessui/react";
import "../../styles/funds.css";

export const ListNumber = ({ type, value }) => {
  return (
    <div className="flex flex-row items-center justify-between mb-[10px]">
      <div style={{ fontSize: 12, fontWeight: "400", color: "#817E7E" }}>
        {type}
      </div>
      <div style={{ fontSize: 12, fontWeight: "700", color: "#363636" }}>
        {value}
      </div>
    </div>
  );
};

export const ColListNumber = ({ type, value }) => {
  return (
    <div>
      <div>{type}</div>
      <div className="flex flex-row items-center">
        <img src={rupeeIcon} alt="Rupee icon" className="w-[12px] h-[12px]" />
        <span
          className="ml-[5px]"
          style={{
            fontSize: 18,
            fontWeight: "600",
            color: "#000000",
          }}
        >
          {value}
        </span>
      </div>
    </div>
  );
};

function Funds() {
  const {
    watchListShown,
    setWatchListShown,
    userprofile,
    getDashboardCall,
    pageName,
    setPageName,
  } = useContext(MyContext);

  const [balance, setBalance] = useState("");

  useEffect(() => {
    //setWatchListShown(true);
    //console.log("user profile : ",userprofile);
    
    getDashboardCall();
    setPageName("funds");
  });

  const onWidrawal = async () => {
    console.log("widrawal api");
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      withdraw: balance,
    };
    // console.log("widrawal body : ",JSON.stringify(body));
    await fetch("https://app.stoxviews.com/api/withdrawfunds", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("value widrawal : ", value);
        if (value.status) {
          getDashboardCall();
          setBalance("");
          toast.success("Widrawal Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error("Widrawal Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(err);
      });
  };

  const onDeposite = async () => {
    console.log("deposite api");
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      balance: balance,
    };
    await fetch("https://app.stoxviews.com/api/addfunds", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("value deposite : ", value);
        if (value.status) {
          getDashboardCall();
          setBalance("");
          toast.success("Deposite Successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Deposite Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error("Deposite Failed", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log(err);
      });
  };

  return (
    <Layout>
      <div
        className=""
        style={{
          width: 959,
          height: 350,
          borderWidth: 1,
          // marginRight: 20,
          // paddingBottom:24,
          marginTop: 16,
          marginLeft: 10,
          borderColor: "#E6E6E6",
          borderRadius: 4,
        }}
      >
        <div
          style={{
            width: "100%",
            marginBottom: 21,
            paddingLeft: 24,
            paddingTop: 24,
            fontSize: 16,
            color: "#363636",
            fontWeight: "700",
          }}
        >
          Funds
        </div>

        <div className="flex flex-row" style={{ width: "100%" }}>
          <div style={{ width: 280, height: 250, marginLeft: 24 }}>
            <div className="mb-[29px]">
              <img
                src={CardIcon}
                alt="card icon"
                style={{ width: 280, height: 176 }}
              />
            </div>
            <div className="flex flex-row justify-between">
              <ColListNumber
                type="Total Deposite"
                value={userprofile?.balance?.toFixed(2) || "0.00"}
              />
              <ColListNumber
                type="Total Widrawal"
                value={userprofile?.total_withdrawl?.toFixed(2) || "0.00"}
              />
            </div>
          </div>

          <div
            className="mr-[42px] ml-[42px]"
            style={{
              width: 340,
              height: 250,
              borderLeftWidth: 0.5,
              borderRightWidth: 0.5,
              borderColor: "#E6E6E6",
              paddingLeft: 42,
              paddingRight: 42,
            }}
          >
            <div style={{ fontSize: 12, color: "#817E7E", marginBottom: 8 }}>
              Topup Your Demo Account
            </div>
            <input
              type="text"
              placeholder="Enter Amount"
              value={balance}
              onChange={(e) => {
                setBalance(e.target.value);
              }}
              style={{
                paddingTop: 9,
                paddingBottom: 9,
                paddingLeft: 16,
                paddingRight: 16,
                borderWidth: 0.5,
                borderColor: "#D9D9D9",
                borderRadius: 4,
                fontSize: 12,
                color: "#817E7E",
              }}
            />
            <div className="flex flex-row items-center mt-[10px] ">
              {/* <Button className="rounded bg-sky-600 py-2 px-4 text-sm text-white data-[hover]:bg-sky-500 data-[active]:bg-sky-700">
                Save changes
              </Button> */}

              <button
                onClick={onDeposite}
                className="hover:bg-blue-700 deposite-btn"
                style={{
                  width: 96,
                  height: 32,
                  fontSize: 12,
                  backgroundColor: "#2992EC",
                  borderRadius: 4,
                  marginRight: 8,
                  color: "white",
                }}
              >
                Deposite
              </button>
              <button
                onClick={onWidrawal}
                className="hover:bg-blue-700 widrawal-button"
                style={{
                  width: 96,
                  height: 32,
                  fontSize: 12,
                  backgroundColor: "#DF2123",
                  borderRadius: 4,
                  color: "white",
                }}
              >
                Withdrawal
              </button>
            </div>

            <div className="flex items-center justify-center mb-[15px] mt-[36px]">
              <ColListNumber
                type="Opening Balance"
                value={userprofile?.opening_balance?.toFixed(2) || "0.00"}
              />
            </div>

            <div className="flex flex-row justify-between">
              <ColListNumber
                type="Available Margin"
                value={userprofile?.balance?.toFixed(2) || "0.00"}
              />
              <ColListNumber
                type="Margin Used"
                value={userprofile.margin_used?.toFixed(2) || "0.00"}
              />
            </div>
          </div>
          <div style={{ width: 207, height: 250, paddingTop: 10 }}>
            <ListNumber type="Payin" value="1,00,000.00" />
            <ListNumber type="Payout" value="0.00" />
            <ListNumber type="Var Margin" value="0.00" />
            <ListNumber type="ELM" value="0.00" />
            <ListNumber type="SPAN" value="0.00" />
            <ListNumber type="Exposure" value="0.00" />
            <ListNumber type="Options premium" value="0.00" />
            <ListNumber type="Collatera" value="0.00" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Funds;
