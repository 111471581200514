import React, { useState, useEffect, useRef } from "react";
import MyContext from "./MyContext";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getNextDailyBarTime } from "../components/TradingViewChart/streaming";

const channelToSubscription = new Map();

const MyState = (props) => {
  const dispatch = useDispatch();
  const sessionID = useSelector((state) => state.session.sessionID);
  const [isDemoPage, setIsDemoPage] = useState(
    localStorage.getItem("currentDashboard") === "live" ? false : true
  );
  const [isLivePage, setIsLivePage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [watchListShown, setWatchListShown] = useState(true);
  const [watch_list, set_watch_list] = useState([]);
  const [userprofile, setuserprofile] = useState({});
  const [childAccount, setChildAccount] = useState([]);
  const [isChildAccount, setIsChildAccount] = useState(false);
  const [authToken, setAuthToken] = useState("");
  const [pageName, setPageName] = useState("dashboard");
  const [authError, setAuthError] = useState("");
  const [authChecked, setAuthChecked] = useState(false);
  const [chartToken, setChartToken] = useState("26000");
  const [isToggled, setIsToggled] = useState(false);
  const [bopen, setBopen] = useState(false);
  const [page, setPage] = useState(["a", "b", "c", "d", "e"]);
  const [buyAndSellExchangeToken, setBuyAndSellExchangeToken] = useState("");
  const [buyAndSellExchange, setBuyAndSellExchange] = useState("NSE");
  const [buyAndSellTradingSymbol, setBuyAndSellTradingSymbol] =
    useState("ATUL-EQ");
  const [buyAndSellInstrumentType, setBuyAndSellInstrumentType] = useState("");
  const [buyAndSellAction, setBuyAndSellAction] = useState("BUY");
  const [optionChainExpiry, setOptionChainExpiry] = useState([]);
  const [optionchainwatchlist, setOptionChainWatchList] = useState([]);
  const [optionchainwatchlist2, setOptionChainWatchList2] = useState([]);
  const [optionChainDate, setOptionChainDate] = useState();
  const [positions, setPositions] = useState([]);
  const [totalLTP, setTotalLTP] = useState(0);
  const [totalPNL, setTotalPNL] = useState(0);
  const [qty, setQty] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [scripts, setScripts] = useState([]);
  const [isScriptOrBased, setIsScriptOrBased] = useState(false);
  const [buyAndSellModalLivePrice, setBuyAndSellModalLivePrice] =
    useState("0.00");
  const [ltpValue, setLtpValue] = useState(24600);
  const [headLivePrice, setHeadLivePrice] = useState({
    change: 0.0,
    last_price: 0.0,
    percentage: 0.0,
  });
  const [retryAttempts, setRetryAttempts] = useState(1);
  const [brokers, setBrokers] = useState([]);
  const [isMasterOrChildOrder, setIsMasterOrChildOrder] = useState(true);
  const [isMasterOrChildPosition, setIsMasterOrChildPosition] = useState(true);
  const [scriptSettingBasedOn, setScriptSettingBasedOn] = useState([]);
  const [myWatchList, setMyWatchList] = useState({});
  const [watchingList, setWatchingList] = useState([]);
  const [demoHolding, setDemoHolding] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0.0);
  const [totalCur, setTotalCur] = useState(0.0);
  const [totalPnl, setTotalPnl] = useState(0.0);
  const ws = useRef(null);

  useEffect(() => {
    if (sessionID !== null) {
      getDashboardCall();
      getWatchLists();
      getPosition();
      getHoldingPageData();
      callGetOptionChain();
      onScriptSetting();
    }
    if (!ws.current) {
      websocketconnect();
    }
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [sessionID, retryAttempts]);

  const websocketconnect = () => {
    if (!sessionID) {
      return;
    }
    ws.current = new WebSocket(
      `wss://ndyz9236e3.execute-api.us-east-1.amazonaws.com/test?token=${JSON.parse(
        localStorage.getItem("sessionID")
      )}`
    );

    ws.current.onopen = () => {
      console.log("WebSocket connected");
      const subscriptionRequest = {
        action: "sendMessage",
        message: {
          event: "subscribe",
          data: "t",
        },
      };
      ws.current.send(JSON.stringify(subscriptionRequest));
    };

    ws.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("on message recieved : ", message);

      if (message.event === "tokenBroadcast") {
        let dt = message.data;

        dt.forEach((tick) => {
          let dstring = tick.exchange_token;
          const tradePrice = tick.last_price;
          let tradeTime = new Date();
          tradeTime = tradeTime.getTime();
          const subscriptionItem = channelToSubscription.get(dstring);

          if (subscriptionItem === undefined) {
            // console.log("not find")
            return;
          }
          // console.log("trade time",tradeTime)
          const lastDailyBar = subscriptionItem.lastDailyBar;
          const nextDailyBarTime = getNextDailyBarTime(lastDailyBar.time);

          let bar;
          if (tradeTime >= nextDailyBarTime) {
            // console.log("next daily")
            bar = {
              time: nextDailyBarTime,
              open: tick.open,
              high: tick.high,
              low: tick.low,
              close: tick.close,
            };
            // console.log('[socket] Generate new bar', bar);
          } else {
            // console.log("last daily")
            bar = {
              ...lastDailyBar,
              high: Math.max(lastDailyBar.high, tick.last_price),
              low: Math.min(lastDailyBar.min, tick.last_price),
              close: tick.last_price,
            };
            // console.log('[socket] Update the latest bar by price', tradePrice);
          }

          // console.log('[socket] Update the latest bar by price', tradePrice);
          subscriptionItem.lastDailyBar = bar;

          // Send data to every subscriber of that symbol
          subscriptionItem.handlers.forEach((handler) => handler.callback(bar));
        });

        let web_data = message.data;

        web_data?.forEach((wd) => {
          setMyWatchList((prevWatchList) => {
            const updatedBuckets = { ...prevWatchList };

            Object.keys(updatedBuckets).forEach((bucketKey) => {
              const bucket = updatedBuckets[bucketKey];
              const instruments = { ...bucket.instruments };

              const instrumentKey = Object.keys(instruments).find(
                (key) =>
                  instruments[key]?.fields?.exchange_token ===
                  wd?.exchange_token
              );

              if (instrumentKey) {
                instruments[instrumentKey] = {
                  ...instruments[instrumentKey],
                  fields: {
                    ...instruments[instrumentKey].fields,
                  },
                  last_price: wd?.last_price,
                  average_traded_price: wd?.average_traded_price,
                  price_change: wd?.change,
                };

                updatedBuckets[bucketKey] = {
                  ...bucket,
                  instruments: instruments,
                };
              }
            });

            return updatedBuckets;
          });
        });

        set_watch_list((prevWatchList) => {
          const new_watch_list = prevWatchList?.map(
            (watchlistItem, watchlistIndex) => {
              let token = watchlistItem.fields;
              const matchingWebItem = web_data?.find(
                (webItem) => webItem.exchange_token === token.exchange_token
              );

              if (matchingWebItem !== undefined) {
                watchlistItem.last_price = matchingWebItem?.last_price;
                watchlistItem.average_traded_price =
                  matchingWebItem?.average_traded_price;
                watchlistItem.change_price = matchingWebItem?.change;
              }

              return watchlistItem;
            }
          );

          return new_watch_list;
        });

        setOptionChainWatchList((new_optionchain_watchlistData) => {
          const new_optionchain_watchlist = new_optionchain_watchlistData?.map(
            (optionChainWatchItem, optionChainWatchIndex) => {
              const matchingWebItem1 = web_data?.find(
                (webItem) =>
                  webItem.exchange_token ===
                  optionChainWatchItem[1].exchange_token
              );

              if (matchingWebItem1 !== undefined) {
                optionChainWatchItem[1].last_price =
                  matchingWebItem1?.last_price;
                optionChainWatchItem.change_price_c = matchingWebItem1?.change;
              } else {
                //console.log("token is not matching 2");
              }
              return optionChainWatchItem;
            }
          );
          return new_optionchain_watchlist;
        });

        setOptionChainWatchList((new_optionchain_watchlistData) => {
          const new_new_optionchain_watchlist =
            new_optionchain_watchlistData?.map(
              (optionChainWatchItem, optionChainWatchIndex) => {
                const matchingWebItem2 = web_data?.find(
                  (webItem) =>
                    webItem.exchange_token ===
                    optionChainWatchItem[0].exchange_token
                );

                if (matchingWebItem2 !== undefined) {
                  optionChainWatchItem[0].last_price =
                    matchingWebItem2?.last_price;
                  optionChainWatchItem.change_price_p =
                    matchingWebItem2?.change;
                } else {
                }
                return optionChainWatchItem;
              }
            );
          return new_new_optionchain_watchlist;
        });

        // const new_new_optionchain_watchlist = new_optionchain_watchlist?.map(
        //   (optionChainWatchItem, optionChainWatchIndex) => {
        //     const matchingWebItem2 = web_data?.find(
        //       (webItem) =>
        //         webItem.exchange_token ===
        //         optionChainWatchItem[0].exchange_token
        //     );

        //     if (matchingWebItem2 !== undefined) {
        //       optionChainWatchItem.last_traded_price_p =
        //         matchingWebItem2?.last_price;
        //       optionChainWatchItem.average_traded_price_p =
        //         matchingWebItem2?.average_traded_price;
        //       optionChainWatchItem.change_price_p = matchingWebItem2?.change;
        //     } else {
        //     }
        //     return optionChainWatchItem;
        //   }
        // );

        // setOptionChainWatchList(new_new_optionchain_watchlist);

        setPositions((positionsData) => {
          const new_positions = positionsData?.map((item, index) => {
            const matchingWebItem2 = web_data?.find(
              (webItem) =>
                webItem.exchange_token === item.instrument__exchange_token
            );

            if (matchingWebItem2 !== undefined) {
              item.last_traded_price = matchingWebItem2?.last_price;
              item.average_traded_price =
                matchingWebItem2?.average_traded_price;
            }
            return item;
          });
          return new_positions;
        });

        setDemoHolding((holdings) => {
          const newDemoHolding = holdings.map((item, index) => {
            const matchingWebItem2 = web_data?.find(
              (webItem) =>
                webItem.exchange_token === item.instrument__exchange_token
            );

            if (matchingWebItem2 !== undefined) {
              item.last_price = matchingWebItem2?.last_price;
              item.change = matchingWebItem2?.change;
            }
            return item;
          });
          return newDemoHolding;
        });

        // const new_positions = positions?.map((item, index) => {
        //   const matchingWebItem2 = web_data?.find(
        //     (webItem) =>
        //       webItem.exchange_token === item.instrument__exchange_token
        //   );

        //   if (matchingWebItem2 !== undefined) {
        //     item.last_traded_price = matchingWebItem2?.last_price;
        //     item.average_traded_price = matchingWebItem2?.average_traded_price;
        //   }
        //   return item;
        // });
        // setPositions(new_positions);

        const nwData = web_data?.find(
          (item, index) => item.exchange_token === "26000"
        );

        if (nwData !== undefined) {
          setHeadLivePrice({
            change: nwData?.change,
            last_price: nwData?.last_price,
          });
        }

        setScripts((scr) => {
          const new_script = scr?.map((item, index) => {
            let token_id = item.token_id;

            const matchingWebItem2 = web_data?.find(
              (webItem) => webItem.exchange_token === token_id?.exchange_token
            );

            if (matchingWebItem2 !== undefined) {
              item.ltp = matchingWebItem2?.last_price;
              item.change = matchingWebItem2?.change;
            }
            return item;
          });

          return new_script;
        });
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket closed");
      ws.current = null;
    };

    ws.current.onerror = (error) => {
      // console.error("WebSocket error:", error);
      setRetryAttempts(retryAttempts + 1);
      ws.current.close();
    };
  };

  const getDashboardCall = async (sID) => {
    await fetch("https://app.stoxviews.com/api/dashboard", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("getting dashboard value : ", value);

        if (value.status) {
          let val = value.data;

          //  console.log("user profile : ", val.userprofile);

          setuserprofile(val.userprofile);
          let wl = val.watch_list;
          // const newWathlist = wl.watchlist.map((item, index) => {
          //   item.isProgress = false;
          //   item.isHover = false;
          //   item.isAdd = false;
          //   item.last_traded_price = 0.0;
          //   item.average_traded_price = 0.0;
          //   item.change_price = 0.0;
          //   return item;
          // });
          // set_watch_list(newWathlist);
          const new_child_account = val.child_accounts.map((item, index) => {
            item.loader = false;
            return item;
          });

          setChildAccount(new_child_account);
        }
        if (value.status === "AuthError") {
          localStorage.clear("");
          //window.location.href = "/";
          toast.error("Logout Failed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          localStorage.clear("");
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
        localStorage.clear("");
      });
  };

  const getWatchLists = async (sID) => {
    await fetch("https://app.stoxviews.com/api/getwatchlist", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("getting watch list datas : ", value);
        if (value.status) {
          let data = value.data;
          const updatedData = { ...data };

          Object.keys(updatedData).forEach((bucketKey) => {
            if (bucketKey.startsWith("bucket_")) {
              const bucket = updatedData[bucketKey];

              if (bucket.instruments) {
                Object.keys(bucket.instruments).forEach((instrumentKey) => {
                  const instrument = bucket.instruments[instrumentKey];

                  // console.log("instrument key : ", instrument);

                  bucket.instruments[instrumentKey] = {
                    ...instrument,
                    isProgress: false,
                    isHover: false,
                    isAdd: false,
                    average_traded_price: 0.0,
                    price_change: instrument.price_change,
                    last_price:
                      instrument.last_price === null
                        ? 0.0
                        : instrument.last_price,
                  };
                });
              }
            }
          });

          setMyWatchList(updatedData);

          // setMyWatchList((prevBuckets) => {
          //   const updatedBuckets = { ...prevBuckets };

          //   Object.keys(updatedBuckets).forEach((bucketKey) => {
          //     const bucket = updatedBuckets[bucketKey];
          //     const instruments = bucket.instruments;

          //     // Find the matching instrument in the current bucket
          //     const instrumentKey = Object.keys(instruments).find(
          //       (key) =>
          //         instruments[key]?.fields?.instrument_token ===
          //         tick.exchange_token
          //     );

          //     if (instrumentKey) {
          //       instruments[instrumentKey] = {
          //         ...instruments[instrumentKey],
          //         fields: {
          //           ...instruments[instrumentKey].fields,
          //           last_traded_price: tick?.last_price,
          //           average_traded_price: tick?.average_traded_price,
          //           change_price: tick?.change,
          //         },
          //       };
          //     }
          //   });

          //   return updatedBuckets;
          // });
        }

        // if (value.status) {
        //   let val = value.data;

        //   let wl = val.watch_list;
        //   const newWathlist = wl.watchlist.map((item, index) => {
        //     item.isProgress = false;
        //     item.isHover = false;
        //     item.isAdd = false;
        //     item.last_traded_price = 0.0;
        //     item.average_traded_price = 0.0;
        //     item.change_price = 0.0;
        //     return item;
        //   });
        //   set_watch_list(newWathlist);
        //   const new_child_account = val.child_accounts.map((item, index) => {
        //     item.loader = false;
        //     return item;
        //   });

        //   setChildAccount(new_child_account);
        // }
        // if (value.status === "AuthError") {
        //   localStorage.clear("");
        //   //window.location.href = "/";
        //   toast.error("Logout Failed", {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "colored",
        //   });
        //   localStorage.clear("");
        // }
      })
      .catch((err) => {
        console.log("Error : ", err);
        //localStorage.clear("");
      });
  };

  const calculateChange = (exchange) => {
    let e = 1;

    if (exchange === "NSE") {
      e = 1;
    } else if (exchange === "NFO") {
      e = 2;
    } else if (exchange === "MCX") {
      e = 5;
    }
    return e;
  };

  const getPosition = async (sID) => {
    setLoading(true);

    await fetch("https://app.stoxviews.com/api/getposition", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        if (value.status) {
          let DATA = value.data;
          const newDATA = DATA.map((item, index) => {
            // console.log("position ===> : ", item);

            if (item.instrument__last_price === null) {
              fetch("https://app.stoxviews.com/api/getltp", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${
                    sID === undefined ? sessionID : sID
                  }`,
                },
                body: JSON.stringify({
                  exchange_token: item.instrument__exchange_token,
                  exchange: item.instrument__exchange,
                }),
              })
                .then((res) => res.json())
                .then((ltp) => {
                  console.log("new Ltp", ltp);
                  let d = ltp.data;
                  if (ltp.status) {
                    item.last_traded_price = d.ltp;
                  }
                });
            } else {
              item.last_traded_price = item?.instrument__last_price;
              item.change_price = item.change_price;
              item.isAddOrExitBtnOpen = false;
            }
            // item.last_traded_price =
            //   item?.instrument__last_price === null
            //     ? "0.00"
            //     : item?.instrument__last_price;

            return item;
          });
          setPositions(newDATA);
        } else {
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  // const getLtp = async (sID,item) => {
  //   await fetch("https://app.stoxviews.com/api/getltp", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
  //     },
  //     body: JSON.stringify({ exchange_token: "64397", exchange: "NFO" }),
  //   })
  //     .then((res) => res.json())
  //     .then((value) => {
  //       if (value.status) {
  //         let DATA = value.data;
  //         const newDATA = DATA.map((item, index) => {
  //           item.last_traded_price = item?.instrument__last_price;

  //           item.change_price = item.change_price;
  //           item.isAddOrExitBtnOpen = false;
  //           return item;
  //         });
  //         setPositions(newDATA);
  //       } else {
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //     return {newLtp:0}
  // };

  const onScriptSetting = async () => {
    setLoading(true);
    //const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    await fetch("https://app.stoxviews.com/api/scripsetting", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      //body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("SCRIPT SETTING TABLE DATA FETCHED: ", value);

        if (value.status) {
          setLoading(false);
          setScripts(value.data);
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const callGetOptionChain = async (sID) => {
    await fetch(`https://app.stoxviews.com/api/getoptionchain`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        const v = value.expiry;
        setOptionChainDate(v[0][1]);
        setOptionChainExpiry(value.expiry);

        if (value.optionchain.length === 0) {
          setLoading(false);
        }
        const newoptionchain = value.optionchain.map((item, index) => {
          //console.log("optionChain : ", item);
          if (item[0].last_price === null) {
            fetch("https://app.stoxviews.com/api/getltp", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
              },
              body: JSON.stringify({
                exchange_token: item[0].exchange_token,
                exchange: "NFO",
              }),
            })
              .then((res) => res.json())
              .then((ltp) => {
                //console.log("new Ltp", ltp);
                let d = ltp.data;
                if (ltp.status) {
                  item[0].last_price = d.ltp;
                }
              });
          }

          if (item[1].last_price === null) {
            fetch("https://app.stoxviews.com/api/getltp", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${sID === undefined ? sessionID : sID}`,
              },
              body: JSON.stringify({
                exchange_token: item[1].exchange_token,
                exchange: "NFO",
              }),
            })
              .then((res) => res.json())
              .then((ltp) => {
                // console.log("new Ltp", ltp);
                let d = ltp.data;
                if (ltp.status) {
                  item[1].last_price = d.ltp;
                }
              });
          }
          item.isProgress = false;
          item.isHover = false;
          item.isCallHover = false;
          item.isPutHover = false;
          item.isAdd = false;
          item.change_price_p = 0.0;
          item.change_price_c = 0.0;
          return item;
        });

        setOptionChainWatchList(newoptionchain);
        setOptionChainWatchList2(newoptionchain);
        setLtpValue(value.ltp);
      })
      .catch((err) => {
        console.log("get option chain watchlist error :", err);
      });
  };

  const getHoldingPageData = async () => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    await fetch("https://app.stoxviews.com/api/getholdingsdemo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        if (value.status) {
          let hdata = value.data;
          let newData = hdata.map((item, index) => {
            item.change = 0.0;
            item.isHover = false;
            return item;
          });
          console.log("getholdingsdemo : ", newData);
          setDemoHolding(newData);
          const totalBuyValue = hdata.reduce((sum, item) => {
            return sum + parseFloat(item.buy_avg) * parseFloat(item.buy_qty);
          }, 0.0);
          setTotalInvestment(totalBuyValue);

          const totalCur = hdata.reduce((sum, item) => {
            return sum + item.buy_qty * parseFloat(item.instrument__last_price);
          }, 0);
          setTotalCur(totalCur);

          const totalPnl = hdata.reduce((sum, item) => {
            let cur_value =
              item.buy_qty * parseFloat(item.instrument__last_price);
            let pnl = cur_value - item.buy_avg * item.buy_qty;
            return sum + pnl;
          }, 0);
          setTotalPnl(totalPnl);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const getAccountList = async () => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    await fetch("https://app.stoxviews.com/api/getchild", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("account list get child : ", value);
        let apidata = value.data;
        if (value.status) {
          const newBrokers = apidata.map((item, index) => {
            item.isEditEnabled = false;
            return item;
          });
          setBrokers(newBrokers);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  const getScriptSettingBasedOn = async () => {
    //const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    await fetch("https://app.stoxviews.com/api/basedon", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("script setting based on result : ", value);
        if (value.status) {
          setScriptSettingBasedOn(value.data);
        }
      })
      .catch((err) => {
        console.log("Error : ", err);
      });
  };

  return (
    <MyContext.Provider
      value={{
        buyAndSellTradingSymbol,
        setBuyAndSellTradingSymbol,
        buyAndSellExchange,
        setBuyAndSellExchange,
        buyAndSellExchangeToken,
        setBuyAndSellExchangeToken,
        buyAndSellInstrumentType,
        setBuyAndSellInstrumentType,
        isDemoPage,
        setIsDemoPage,
        isLivePage,
        setIsLivePage,
        loading,
        setLoading,
        watchListShown,
        setWatchListShown,
        watch_list,
        set_watch_list,
        userprofile,
        isChildAccount,
        setIsChildAccount,
        setAuthToken,
        getDashboardCall,
        getPosition,
        callGetOptionChain,
        childAccount,
        pageName,
        setPageName,
        chartToken,
        setChartToken,
        isToggled,
        setIsToggled,
        bopen,
        setBopen,
        buyAndSellAction,
        setBuyAndSellAction,
        optionChainExpiry,
        optionchainwatchlist,
        optionChainDate,
        setOptionChainDate,
        positions,
        setPositions,
        getPosition,
        totalLTP,
        totalPNL,
        optionchainwatchlist2,
        setOptionChainWatchList,
        qty,
        setQty,
        buyAndSellModalLivePrice,
        setBuyAndSellModalLivePrice,
        ltpValue,
        ws,
        headLivePrice,
        scripts,
        setScripts,
        onScriptSetting,
        setChildAccount,
        brokers,
        setBrokers,
        getAccountList,
        isScriptOrBased,
        setIsScriptOrBased,
        getScriptSettingBasedOn,
        scriptSettingBasedOn,
        setScriptSettingBasedOn,
        isMasterOrChildOrder,
        setIsMasterOrChildOrder,
        isMasterOrChildPosition,
        setIsMasterOrChildPosition,
        myWatchList,
        setMyWatchList,
        getWatchLists,
        watchingList,
        setWatchingList,
        lotSize,
        setLotSize,
        demoHolding,
        setDemoHolding,
        totalInvestment,
        setTotalInvestment,
        totalCur,
        setTotalCur,
        totalPnl,
        setTotalPnl,
      }}
    >
      {props.children}
    </MyContext.Provider>
  );
};
export { channelToSubscription };
export default MyState;
