import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";

//layouts

import Layout from "../../components/layout/Layout";

// importing icons
import { BiUnlink } from "react-icons/bi";
import equitiesIcon from "../../assets/icons/equities.png";
import featuresIcon from "../../assets/icons/features.png";
import optionsIcon from "../../assets/icons/Options.png";
import currencyIcon from "../../assets/icons/currency.png";
import commoditiesIcon from "../../assets/icons/commodities.png";
import closedIcon from "../../assets/icons/closed.png";
import profileIcon from "../../assets/icons/profile.png";
import greenprofileIcon from "../../assets/icons/greenprofile.png";
import reloadIcon from "../../assets/icons/reload.png";
import rupeeIcon from "../../assets/icons/rupee.png";

import MyContext from "../../context/MyContext";

import { toast } from "react-toastify";
import { SmallLoader } from "../../components/loader/Loader";

function Dashboard() {
  const {
    isDemoPage,
    setIsDemoPage,
    isLivePage,
    setIsLivePage,
    setWatchListShown,
    userprofile,
    getDashboardCall,
    childAccount,
    pageName,
    setPageName,
    setChildAccount,
    totalInvestment,
    setTotalInvestment,
    totalCur,
    setTotalCur,
    totalPnl,
    setTotalPnl,
  } = useContext(MyContext);

  const [stockItem, setStockItem] = useState([
    { name: "Equities", type: "Trade in company shares", img: equitiesIcon },
    { name: "Currency", type: "Trade in USD, EURO & more", img: currencyIcon },
    { name: "Futures", type: "< Discover future to trades", img: featuresIcon },
    { name: "Options", type: "Find option to trade in", img: optionsIcon },
    {
      name: "Commodities",
      type: "Trade in goods and matals",
      img: commoditiesIcon,
    },
  ]);

  const [BrokerageStatus, setBrokerageStatus] = useState([
    { isConnected: false, name: "Kumar", company: "Algoconnect" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
    { isConnected: true, name: "Reena", company: "Zeroda" },
  ]);

  const [childBrokerLoader, setChildBrokerLoader] = useState(false);

  const [refreshBrokerageStatus, setRefreshBrokerageStatus] = useState(false);

  useEffect(() => {
    // console.log("user profile : ", userprofile);

    getDashboardCall();
    setPageName("dashboard");
  }, []);

  function checkMarketStatus() {
    const now = new Date();

    const marketOpen = new Date();
    marketOpen.setHours(9, 30, 0);

    const marketClose = new Date();
    marketClose.setHours(15, 30, 0);

    if (now >= marketOpen && now <= marketClose) {
      return "Market open";
    } else {
      return "Market closed";
    }
  }

  const connectedOrExpired = (broker_last_connected) => {
    const expiredDay = new Date(broker_last_connected).getUTCDay();
    const currentDay = new Date().getUTCDay();
    if (expiredDay < currentDay) {
      return {
        status: "Expired",
        color: "#721C24",
        backgroundColor: "#F8D7DA",
      };
    } else {
      return {
        status: "Connected",
        color: "#155724",
        backgroundColor: "#D4EDDA",
      };
    }
  };

  const childconnectedOrExpired = (child_broker_last_connected) => {
    const expiredDay = new Date(child_broker_last_connected).getUTCDay();
    const currentDay = new Date().getUTCDay();
    if (expiredDay < currentDay) {
      return {
        status: "Expired",
        color: "#721C24",
        backgroundColor: "#F8D7DA",
      };
    } else {
      return {
        status: "Connected",
        color: "#155724",
        backgroundColor: "#D4EDDA",
      };
    }
  };

  const onClickBrokerageStatusCall = async () => {
    setRefreshBrokerageStatus(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    await fetch("https://app.stoxviews.com/api/refreshBrokerToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("Brokerage status refreshed : ", value);

        if (value.status) {
          setRefreshBrokerageStatus(false);
          getDashboardCall();
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setRefreshBrokerageStatus(false);
        }
      })
      .catch((err) => {
        setRefreshBrokerageStatus(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const onClickBrokerageDeleteCall = async (id) => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    console.log("", { user_id: id });

    await fetch("https://app.stoxviews.com/api/deleteBrokerToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify({ user_id: id }),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("Brokerage status deleted : ", value);

        if (value.status) {
          getDashboardCall();
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        setRefreshBrokerageStatus(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const refreshBrokerChild = async (id, index) => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    let newchilda = childAccount.map((item, ind) => {
      if (index === ind) {
        item.loader = true;
      } else {
        item.loader = false;
      }
      return item;
    });
    setChildAccount(newchilda);

    await fetch("https://app.stoxviews.com/api/refreshChildBrokerToken", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify({ user_id: id }),
    })
      .then((res) => res.json())
      .then((value) => {
        console.log("Brokerage child deleted : ", value);

        let newchilda = childAccount.map((item, ind) => {
          if (index === ind) {
            item.loader = false;
          }
          return item;
        });
        setChildAccount(newchilda);
        if (value.status) {
          getDashboardCall();
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  return (
    <Layout>
      {isDemoPage ? (
        <div className="flex flex-col justify-between h-full gap-4">
          <div className="flex flex-row gap-4  h-full">
            {/* Portfolio Value Section */}
            <div className="flex flex-col h-full gap-4 w-[70%]  ">
              <div
                style={{ borderColor: "#E6E6E6" }}
                className="flex flex-col  h-1/2 border border-gray-200 rounded-md "
              >
                <div className="flex flex-row justify-between px-6 py-4">
                  <div>
                    <span className="text-lg font-semibold">
                      Portfolio Value
                    </span>
                  </div>
                  <div className="flex flex-row items-baseline">
                    <div className="flex flex-row items-center mr-6">
                      {checkMarketStatus() === "Market open" ? (
                        <div
                          style={{
                            width: 12,
                            height: 12,
                            borderRadius: "100%",
                            backgroundColor: "green",
                            marginRight: 8,
                          }}
                        ></div>
                      ) : (
                        <img
                          src={closedIcon}
                          alt="up icon"
                          style={{ width: 12, height: 12, marginRight: 8 }}
                        />
                      )}
                      <span className="text-sm font-normal">
                        {checkMarketStatus()}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row space-x-9 px-6 pb-5">
                  <div>
                    <div className="text-xs text-gray-500">Investment</div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {totalInvestment.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Current Value</div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {totalCur.toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">
                      Overall Profits/Loss
                    </div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">0.00(%)</span>
                    </div>
                  </div>

                  <div>
                    <div className="text-xs text-gray-500">Today's Profits</div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {totalPnl.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Money in Stoxview Section */}
              <div
                style={{ borderColor: "#E6E6E6" }}
                className="h-1/2 border border-gray-200 rounded-md"
              >
                <div className="px-6 py-4">
                  <span className="text-lg font-semibold">
                    Money in Stoxview
                  </span>
                </div>

                <div className="flex flex-row space-x-9 px-6 pb-6">
                  <div>
                    <div className="text-xs text-gray-500">Account Balance</div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {Object.keys(userprofile).length !== 0
                          ? userprofile?.balance.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Margin Used</div>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {Object.keys(userprofile).length !== 0
                          ? userprofile.margin_used.toFixed(2)
                          : "0.00"}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-gray-500">Opening Balance</div>
                    <div className="flex flex-row items-center">
                      {/* {console.log("userprofile : ",userprofile)} */}
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-3.5 h-3.5 mr-1.5"
                      />
                      <span className="text-lg font-bold">
                        {userprofile?.opening_balance === undefined
                          ? "0.00"
                          : userprofile?.opening_balance?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Add Funds Section */}
            <div
              className="w-[30%] mr-10 border flex items-center flex-col justify-center"
              style={{
                //marginLeft: 16,
                //marginRight: 40,
                //marginTop: 15,
                backgroundColor: "#E6E6E6",
                borderWidth: 1,
                borderColor: "#E6E6E6",
                borderRadius: 6,
                //width: "100%",
                //height:'20%'
              }}
            >
              <span
                style={{
                  marginBottom: 41,
                  color: "#363636",
                  fontSize: 16,
                  fontWeight: "500",
                }}
              >
                Add Funds
              </span>
              <div className="flex flex-row items-center mb-[44px]">
                <img
                  src={rupeeIcon}
                  alt="Rupee icon"
                  className="w-[28px] h-[28px] mr-[10px]"
                />
                <span style={{ fontSize: 36, fontWeight: "700" }}>
                  {Object.keys(userprofile).length !== 0
                    ? userprofile?.balance.toFixed(2)
                    : "0.00"}
                </span>
              </div>

              <Link to="/funds">
                <button
                  style={{
                    backgroundColor: "#155724",
                    color: "white",
                    width: 158,
                    height: 40,
                    borderRadius: 4,
                  }}
                >
                  <span style={{ marginVertical: "12px" }}>Add Money</span>
                </button>
              </Link>
            </div>
          </div>

          {/* Invest/Trade Section */}
          <div
            className="h-1/2 border border-gray-200 rounded-md mr-10 mb-8"
            style={{ borderColor: "#E6E6E6" }}
          >
            <div className="px-6 py-4">
              <span className="text-lg font-semibold">
                Invest/Trade on Stock Exchanges
              </span>
            </div>
            <div className="flex flex-row flex-wrap px-10">
              {stockItem.map((item, index) => (
                <div
                  key={index}
                  className="mr-9 mb-5 flex flex-row items-center"
                >
                  <img src={item.img} alt="" className="w-8 h-8 mr-3" />
                  <div className="flex flex-col justify-center">
                    <span>{item.name}</span>
                    <span>{item.type}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            // paddingLeft: 20,
          }}
        >
          <div className="flex flex-row justify-between items-center mr-[34px] mt-[24px]">
            <span style={{ fontSize: 16, fontWeight: "500" }}>Dashboard</span>
            <div
              className="flex flex-row items-baseline"
              style={{ marginRight: 24, marginTop: 21 }}
            >
              <div className="flex flex-row items-center">
                {checkMarketStatus() === "Market open" ? (
                  <div
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: "100%",
                      backgroundColor: "green",
                      marginRight: 8,
                    }}
                  ></div>
                ) : (
                  <img
                    src={closedIcon}
                    alt="up icon"
                    style={{ width: 12, height: 12, marginRight: 8 }}
                  />
                )}
              </div>
              <span
                style={{
                  //color:checkMarketStatus() === "Market open" ? "green" : "red",
                  fontSize: 12,
                  fontWeight: "400",
                }}
              >
                {checkMarketStatus()}
              </span>
            </div>
          </div>

          <div className="flex flex-1 flex-row mt-[24px]">
            <div className="flex  flex-col w-[600px] mr-[22px]">
              <div
                style={{
                  width: 590,
                  height: 129,
                  borderRadius: 4,
                  backgroundColor: "#FFFFFF",
                  borderStyle: "solid",
                  borderWidth: 1,
                  borderColor: "rgba(230, 230, 230, 1.0)",
                }}
              >
                <h2
                  className="ml-[24px] mt-[21px] mb-[24px]"
                  style={{
                    fontSize: 16,
                    fontWeight: "500",
                    color: "#363636",
                  }}
                >
                  Portfolio value
                </h2>

                <div className="flex flex-row justify-around">
                  <div>
                    <h2
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#817E7E",
                      }}
                    >
                      Investment
                    </h2>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-[12px] h-[12px]"
                      />
                      <span
                        className="ml-[5px]"
                        style={{
                          fontSize: 18,
                          fontWeight: "700",
                          color: "#000000",
                        }}
                      >
                        0.00
                      </span>
                    </div>
                  </div>

                  <div>
                    <h2
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#817E7E",
                      }}
                    >
                      Current Value
                    </h2>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-[12px] h-[12px]"
                      />
                      <span
                        className="ml-[5px]"
                        style={{
                          fontSize: 18,
                          fontWeight: "700",
                          color: "#000000",
                        }}
                      >
                        0.00
                      </span>
                    </div>
                  </div>

                  <div>
                    <h2
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#817E7E",
                      }}
                    >
                      Overall Profilts/Loss
                    </h2>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-[12px] h-[12px]"
                      />
                      <span
                        className="ml-[5px]"
                        style={{
                          fontSize: 18,
                          fontWeight: "700",
                          color: "#000000",
                        }}
                      >
                        0.00 (%)
                      </span>
                    </div>
                  </div>

                  <div>
                    <h2
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color: "#817E7E",
                      }}
                    >
                      Today's Profit
                    </h2>
                    <div className="flex flex-row items-center">
                      <img
                        src={rupeeIcon}
                        alt="Rupee icon"
                        className="w-[12px] h-[12px]"
                      />
                      <span
                        className="ml-[5px]"
                        style={{
                          fontSize: 18,
                          fontWeight: "700",
                          color: "#000000",
                        }}
                      >
                        0.00 (%)
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="flex flex-1 flex-col w-[307px] mr-[20px] pb-[8px] overflow-y-auto max-h-[430px]"
              style={{
                borderRadius: 4,
                backgroundColor: "#FFFFFF",
                borderStyle: "solid",
                borderWidth: 1,
                borderColor: "rgba(230, 230, 230, 1.0)",
              }}
            >
              <h2
                className="mt-[21px] ml-[23px] mb-[24px]"
                style={{
                  fontSize: 16,
                  fontWeight: "500",
                  color: "#363636",
                }}
              >
                Brokerage status
              </h2>

              <div className="flex flex-row items-center ml-[23px] mb-[16px]">
                <img
                  src={
                    userprofile?.broker !== "stoxview"
                      ? greenprofileIcon
                      : profileIcon
                  }
                  alt="profile icon"
                  className="w-[24px] h-[24px] mr-[8px]"
                />
                <div className="flex flex-col">
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#363636",
                    }}
                  >
                    {userprofile?.fname}
                  </span>
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: "400",
                      color: "#817E7E",
                    }}
                  >
                    {userprofile?.broker}
                  </p>
                </div>
              </div>

              <div className=" flex justify-between items-center w-[160px] ml-[23px] mb-[24px]">
                <div style={{ backgroundColor: "#F8D7DA" }}>
                  <div
                    className="w-[102px] h-[24px] flex items-center justify-center"
                    style={{
                      borderRadius: 2,
                      backgroundColor:
                        userprofile?.broker !== "stoxview"
                          ? connectedOrExpired(
                              userprofile?.broker_last_connected
                            ).backgroundColor
                          : "#F8D7DA",
                    }}
                  >
                    <span
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        color:
                          userprofile?.broker !== "stoxview"
                            ? connectedOrExpired(
                                userprofile?.broker_last_connected
                              ).color
                            : "#721C24",
                      }}
                    >
                      {userprofile?.broker !== "stoxview"
                        ? connectedOrExpired(userprofile?.broker_last_connected)
                            .status
                        : "Disconnected"}
                    </span>
                  </div>
                </div>

                <button
                  className="flex items-center justify-center"
                  style={{
                    width: 24,
                    height: 24,
                    opacity: 0.3,
                    borderRadius: 2,
                    backgroundColor: "#E6E6E6",
                    borderStyle: "solid",
                    borderWidth: 0.5,
                    borderColor: "rgba(0, 0, 0, 1.0)",
                  }}
                  onClick={onClickBrokerageStatusCall}
                >
                  {!refreshBrokerageStatus ? (
                    <img
                      src={reloadIcon}
                      alt=""
                      className="w-[12px] h-[12px] "
                    />
                  ) : (
                    <SmallLoader />
                  )}
                </button>

                <button
                  onClick={() => onClickBrokerageDeleteCall(userprofile.id)}
                >
                  <BiUnlink />
                </button>
              </div>

              <div className="flex flex-col items-center">
                {childAccount?.map((item, index) => {
                  //console.log(item);

                  return (
                    <div className="w-full">
                      <div className="flex flex-row items-center justify-between w-full">
                        <div className="flex flex-row items-center ml-[15px]  ">
                          <img
                            src={
                              item?.user_id__broker !== "stoxview"
                                ? greenprofileIcon
                                : profileIcon
                            }
                            alt="profile icon"
                            className="w-[24px] h-[24px] mr-[8px]"
                          />
                          <div className="flex flex-col">
                            <span
                              style={{
                                fontSize: 14,
                                fontWeight: "400",
                                color: "#363636",
                              }}
                            >
                              {item?.user_id__user_id__username.length > 10
                                ? item?.user_id__user_id__username.substring(
                                    0,
                                    10
                                  ) + "..."
                                : item?.user_id__user_id__username}
                            </span>
                            <p
                              style={{
                                fontSize: 10,
                                fontWeight: "400",
                                color: "#817E7E",
                              }}
                            >
                              {item?.user_id__broker}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-row justify-between items-center w-[154px] mr-[15px] ">
                          <div style={{ backgroundColor: "#F8D7DA" }}>
                            <div
                              className="w-[102px] h-[24px] flex items-center justify-center"
                              style={{
                                borderRadius: 2,
                                backgroundColor:
                                  item?.user_id__broker !== "stoxview"
                                    ? childconnectedOrExpired(
                                        item?.user_id__broker_last_connected
                                      ).backgroundColor
                                    : "#F8D7DA",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: 12,
                                  fontWeight: "400",
                                  color:
                                    item?.user_id__broker !== "stoxview"
                                      ? childconnectedOrExpired(
                                          item?.user_id__broker_last_connected
                                        ).color
                                      : "#721C24",
                                }}
                              >
                                {item?.user_id__broker !== "stoxview"
                                  ? childconnectedOrExpired(
                                      item?.user_id__broker_last_connected
                                    ).status
                                  : "Disconnected"}
                              </span>
                            </div>
                          </div>

                          <button
                            className="flex items-center justify-center"
                            style={{
                              width: 24,
                              height: 24,
                              opacity: 0.3,
                              borderRadius: 2,
                              backgroundColor: "#E6E6E6",
                              borderStyle: "solid",
                              borderWidth: 0.5,
                              borderColor: "rgba(0, 0, 0, 1.0)",
                            }}
                            onClick={() =>
                              refreshBrokerChild(item.user_id_id, index)
                            }
                          >
                            {!item.loader ? (
                              <img
                                src={reloadIcon}
                                alt=""
                                className="w-[12px] h-[12px] "
                              />
                            ) : (
                              <SmallLoader />
                            )}
                          </button>

                          <button
                            onClick={() =>
                              onClickBrokerageDeleteCall(item.user_id_id)
                            }
                          >
                            <BiUnlink />
                          </button>
                        </div>
                      </div>

                      {childAccount.length - 1 !== index && (
                        <div
                          style={{
                            borderStyle: "solid",
                            borderWidth: 1,
                            borderColor: "rgba(230, 230, 230, 1.0)",
                            marginBottom: 8,
                            marginTop: 8,
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
}

export default Dashboard;
